import React from "react";

const Disclaimer = () => {
  return (
    <div className="bg-white w-screen h-full">
      <img src="/images/common/bluelogo.png" className="mx-auto w-[250px] object-cover z-20 mt-[100px]" />
      <p className="font-helveticaBold text-[1.2em] sm:text-[1.5em] text-center mt-7">Disclaimer</p>
      <div className="max-w-[1000px] px-5 mx-auto">
        <p className="font-helveticaRoman text-[1.1em] sm:text-[1.3em] mt-5">© 2024 Admiral Technologies LLC.</p>
        <p className="font-helveticaRoman text-[1.1em] sm:text-[1.3em] mt-5">
          Admiral Securities LLC is a wholly owned subsidiary of Admiral Technologies LLC, and a member of{" "}
          <a href="https://www.finra.org/" target="_blank" style={{ color: "#0295FE" }}>
            FINRA
          </a>
          {" and "}
          <a href="https://www.sipc.org/" target="_blank" style={{ color: "#0295FE" }}>
            SIPC
          </a>
          .
        </p>
        <p className="font-helveticaRoman text-[1.1em] sm:text-[1.3em] mt-5">
          All content on this site is provided for informational purposes only and may be changed without notice. It
          does not constitute research, recommendations, advice, or an offer or solicitation to buy or sell any
          financial products or services. You should consult your own advisors regarding legal, tax, accounting, or
          other considerations, including suitability implications for you.
        </p>
        <p className="font-helveticaRoman text-[1.1em] sm:text-[1.3em] mt-5">
          Unauthorized use of this site is strictly prohibited. Admiral Technologies, LLC and Admiral Securities LLC
          disclaim any responsibility or liability for the quality, accuracy, or completeness of the information herein,
          as well as for any reliance on or use of this material.
        </p>
        <p className="font-helveticaRoman text-[1.1em] sm:text-[1.3em] mt-5">
          No part of the information on this site may be reproduced, redistributed, or transmitted without the prior
          written consent of Admiral Technologies LLC and Admiral Securities LLC.
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;
