import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter, Routes, useNavigate, Navigate, useLocation } from "react-router-dom";
import "animate.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";

import InvestorHeader from "./components/InvestHeader";
import Header from "./components/header";
import Footer from "./components/footer";
import Contact from "./pages/contact";
import Signup from "./pages/signup";
import Login from "./pages/login";

import Home from "./pages/home";
import Landing from "./pages/landing";
import AboutUs from "./pages/aboutus";
import News from "./pages/news";
import FAQ from "./pages/FAQ";
import Pricing from "./pages/pricing";
import Demo from "./pages/demo";
import Investors from "./pages/investors";
import ScrollToTop from "./hooks/scrolltotop";
//Financing
import FinancingHome from "./pages/Financing/Home";
import FinancingLogin from "./pages/Financing/Login";
import FinancingSignup from "./pages/Financing/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import { setAuthState, setTokenState, setTokenExpiresInState, setUser } from "src/redux/slices/financingAuthSlice";
import { isAuthenticatedWithToken } from "src/pages/Financing/Common/common";
import EmailVerification from "./pages/emailverification";
import IntroVideo from "./pages/introvideo";
import Terms from "./pages/Financing/Terms/terms";
import IntroductoryVideo from "./pages/Financing/materials/sub/introductoryvideo";
import SlideDeck from "./pages/Financing/materials/sub/slidedeck";
import DescriptionOfSecurities from "./pages/Financing/materials/sub/descriptionofsecurities";
import Materials from "./pages/Financing/materials/materials";
import Disclaimer from "./pages/disclaimer/Disclaimer";

const rPassword = "Amsterdam";
// const rPassword = "1";

const PrivateRoute = ({ auth: isAuthenticated, children }) => {
  return isAuthenticated ? children : <Navigate to="/financing/login" />;
};

function App() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");

  const isAuthenticated = useSelector((state) => state.financingAuth.isAuthenticated);

  const onVideoLoaded = () => {
    setIsVideoLoaded(true);
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      setCPassword(event.target.value);
    }
  };

  return (
    <>
      <ToastContainer />
      {cPassword === rPassword ? (
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={() => null} />
              {/* <Route path="/login" element={<Header />} /> */}
              {/* <Route path="/signup" element={<Header />} /> */}
              <Route path="/contact" element={<Header />} />
              <Route path="/home" element={<Header />} />
              <Route path="/aboutus" element={<Header />} />
              <Route path="/contactus" element={<Header />} />
              <Route path="/news" element={<Header />} />
              <Route path="/faq" element={<Header />} />
              <Route path="/pricing" element={<Header />} />
              <Route path="/demo" element={<Header />} />
              <Route path="/investors" element={<Header />} />
            </Routes>
            <Routes>
              <Route path="/" element={<Landing onVideoLoaded={onVideoLoaded} />} />
              {/* <Route path="/login" element={<Login />} /> */}
              <Route path="/signup" element={<Signup />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/home" element={<Home />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/contactus" element={<Contact />} />
              <Route path="/news" element={<News />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/demo" element={<Demo />} />
              <Route path="/investors" element={<Investors />} />
              <Route path="/introvideo" element={<IntroVideo />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
            </Routes>

            <Routes>
              <Route path="/" element={isVideoLoaded && <Footer />} />
              {/* <Route path="/login" element={<Footer />} /> */}
              {/* <Route path="/signup" element={<Footer />} /> */}
              <Route path="/contact" element={<Footer />} />
              <Route path="/home" element={<Footer />} />
              <Route path="/aboutus" element={<Footer />} />
              <Route path="/contactus" element={<Footer />} />
              <Route path="/news" element={<Footer />} />
              <Route path="/faq" element={<Footer />} />
              <Route path="/pricing" element={<Footer />} />
              <Route path="/demo" element={<Footer />} />
              <Route path="/investors" element={<Footer />} />
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/introvideo" element={<IntroVideo />} />

            {/* Financing Start*/}
            <Route path="/financing" element={<InvestorHeader />} />
            <Route path="/financing/login" element={<InvestorHeader />} />
            <Route path="/financing/signup" element={<InvestorHeader />} />
            <Route path="/financing/materials" element={<InvestorHeader />} />
            <Route path="/financing/termsandconditions" element={<InvestorHeader />} />
            <Route path="/financing/introductoryvideo" element={<InvestorHeader />} />
            <Route path="/financing/slidedeck" element={<InvestorHeader />} />
            <Route path="/financing/descriptionofsecurities" element={<InvestorHeader />} />
            <Route path="/financing/forgotpassword" element={<InvestorHeader />} />
            <Route path="/financing/resetpassword/:token" element={<InvestorHeader />} />
            <Route path="/auth/verify/:token" element={<InvestorHeader />} />
            {/* Financing End*/}

            <Route
              path="/*"
              element={
                <div className="w-[100wh] h-[100vh] bg-default-color flex items-center justify-center">
                  <input
                    value={password}
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => handleKeyPress(e)}
                    className="w-[300px] h-[40px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg pl-3 pr-3 m-auto"
                  />
                </div>
              }
            />
          </Routes>
          <Routes>
            {/* Financing Start */}
            <Route
              path="/financing"
              element={
                <PrivateRoute auth={isAuthenticated}>
                  <FinancingHome />
                </PrivateRoute>
              }
            />

            <Route path="/financing/login" element={<FinancingLogin />} />
            <Route path="/financing/signup" element={<FinancingSignup />} />
            <Route path="/financing/forgotpassword" element={<ForgotPassword />} />
            <Route path="/financing/termsandconditions" element={<Terms />} />
            <Route path="/financing/materials" element={<Materials />} />
            <Route path="/financing/introductoryvideo" element={<IntroductoryVideo />} />
            <Route path="/financing/slidedeck" element={<SlideDeck />} />
            <Route path="/financing/descriptionofsecurities" element={<DescriptionOfSecurities />} />
            <Route path="/financing/resetpassword/:token" element={<ResetPassword />} />
            <Route path="/auth/verify/:token" element={<EmailVerification />} />
            {/* Financing End */}
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
