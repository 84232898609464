import React from "react";
import OutsideClickHandler from "react-outside-click-handler";

function MemberModal({ member, shown, closeClicked }) {
  // const onBackgroundClicked = (e) => {
  //   props.closeClicked();
  // };

  const facebookClicked = (e) => {
    e.preventDefault();
  };
  const twitterClicked = (e) => {
    e.preventDefault();
  };

  return (
    shown && (
      <div className="fixed w-screen h-screen left-0 top-0 bg-black bg-opacity-80 z-50 flex items-center justify-center">
        <OutsideClickHandler onOutsideClick={closeClicked}>
          <div className="w-[300px] sm:w-[1100px] overflow-auto h-[90vh] sm:h-auto flex-col sm:flex-row flex items-center justify-between relative">
            <img
              src="/images/aboutus/close_btn.png"
              className="w-[35px] h-[35px] absolute top-2 right-2 cursor-pointer z-30"
              onClick={closeClicked}
            />
            <img src={member.photo} className="w-[300px] sm:w-[380px] h-[500px] object-cover" />
            <div className="bg-white w-[300px] sm:w-full relative p-[35px] sm:h-[500px]">
              <p className="text-default-color font-helveticaRoman text-[1.5em] sm:text-[2.0em]">{member.name}</p>
              <p className="text-black font-helveticaRoman text-[1.0em]">{member.job}</p>
              <div className="text-[#737171] font-helveticaRoman text-[1.0em] mt-[15px] ">{member.bio}</div>

              <div className="flex items-center justify-start mt-[20px] gap-3">
                {/* <a href="#" onClick={facebookClicked}>
                  <img src="/images/aboutus/modal_facebook.png" className="w-[45px] h-[45px]" />
                </a>
                <a href="#" onClick={twitterClicked}>
                  <img src="/images/aboutus/modal_twitter.png" className="w-[45px] h-[45px]" />
                </a> */}
                <a href={member.linkedin} target="_blank">
                  <img src="/images/aboutus/modal_linkedin.png" className="w-[45px] h-[45px]" />
                </a>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    )
  );
}

export default MemberModal;
