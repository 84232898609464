import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Member from "../member";
import MemberModal from "../membermodal";

const memberList1 = [
  {
    name: "Gideon Magnus",
    job: "Founder and Chief Executive Officer",
    photo: "/images/aboutus/gideon.jpeg",
    bio: "Dr. Gideon Magnus is the Chief Executive Officer of Admiral Securities LLC. He holds a B.Sc. and M.Sc. in Econometrics from the University of Amsterdam and a Ph.D. in Economics from the University of Chicago. He began his career as a Senior Quantitative Analyst at Morningstar, Inc., contributing to financial forecasts and developing new measures of fund performance. In 2016, he became a Senior Economist at State Street Corporation, researching global financial market impacts under various scenarios. Most recently, he served as Director of Systemic Risk at the Depository Trust and Clearing Corporation (DTCC).",
    linkedin: "https://www.linkedin.com/in/gideonmagnus/",
  },
  {
    name: "John Byrne",
    job: "Chief Compliance Officer",
    photo: "/images/aboutus/john.jpg",
    bio: "John M. Byrne Jr. brings 36 years of securities industry experience, many as a registered principal. From 1987 to 1999, he was Chief Administrative, Compliance, and Operations Officer at ICAP, overseeing non-sales functions and managing over 50 employees. At Bloomberg Tradebook LLC (2000–2019), he helped design the fixed income trading platform and rose to Chief Compliance and AML Officer. Between 2020 and 2021, he served as Chief Compliance and AML Officer at Liquidnet Inc., revamping compliance procedures.",
    linkedin: "https://www.linkedin.com/in/john-byrne-62643313/",
  },
  {
    name: "Keith George",
    job: "Finance and Operations Principal",
    photo: "/images/aboutus/keith.jpg",
    bio: "“Keith George is a licensed CPA and Series 27 holder with extensive experience in financial management within the financial services sector. Currently employed as a regulatory consultant at Accounting and Compliance International (ACI), he provides FinOp licensing services to over 100 broker-dealers nationwide. ACI has a team of 26 full-time staff, including 15 licensed FinOps. Mr. George began his career as an auditor at Ernst & Young in Washington, D.C., and later worked as a bank supervision regulator at the Federal Reserve Bank of New York. He spent ten years at Bank of America Merrill Lynch and has served as Controller and Chief Financial Officer for numerous broker-dealers over the past 20 years.",
    linkedin: "https://www.linkedin.com/in/keith-george-0a4611b4/",
  },
];
const memberList2 = [
  {
    name: "Nathan Tribble",
    job: "Chief Revenue Officer",
    photo: "/images/aboutus/nathan.jpg",
    bio: "Nathan Tribble is a fintech and capital markets expert with 15 years of experience. He currently serves as the CEO of VIKASA Capital Partners and is the co-founder and Managing Director of Heuristics Strategies, a California-based fintech and public policy consultancy. Previously, he was President and Chief Compliance Officer at Stockpile Investments, overseeing operations, compliance, trading, and risk management. He also held positions as Chief Operating Officer for Ally Invest Forex and Ally Invest Futures, and was Director of Digital Partnerships at Ally Financial. Mr. Tribble began his career at Morgan Stanley and fintech startups TradeKing and MB Trading. He holds numerous financial licenses—including Series 3, 4, 7, 24, 30, 31, 34, 53, 66, and 79—is a Certified Anti-Money Laundering Specialist (CAMS), and earned his MBA from the Yale School of Management. Active in nonprofits, he serves on the boards of the Stockpile Foundation and Turnkey Speech and Debate.",
    linkedin: "https://www.linkedin.com/in/nathantribble/",
  },
  {
    name: "David Weiss",
    job: "Chief Operations Officer",
    photo: "/images/aboutus/david.jpg",
    bio: "David F. Weiss is a financial industry executive with over 20 years of experience in building and operating large-scale electronic exchanges and marketplaces. He has held senior roles at NYSE and Archipelago, specializing in equities, fixed income, and derivatives electronic trading systems. In 2016, he founded Qayaq Consulting LLC, collaborating with firms like Pipe Technologies and Marathon Asset Management. David has extensive expertise in drafting FIX order entry and market data APIs, integrating third-party systems, and managing major trading system projects. At NYSE and Archipelago, he supervised a team of 15 responsible for trade support and connectivity, managing over 300 customers and 5,000 FIX connections. He holds a degree in English Language and Literature from the University of Illinois Chicago and is FINRA Series 7, 24, and 63 licensed. Outside of his professional work, David has been involved with the Make a Wish Foundation for 20 years, raising over $250,000 through long-distance bicycle trips for children with life-threatening diseases.",
    linkedin: "https://www.linkedin.com/in/daveweissnyse/",
  },
];

function AboutUsContent() {
  const [isModalShown, setIsModalShown] = useState(false);
  const [clickedMember, setClickedMember] = useState(null);

  const memberClicked = (member) => {
    setClickedMember(member);
    setIsModalShown(true);
  };

  const closeModalClicked = () => {
    setIsModalShown(false);
  };

  return (
    <>
      <div className="animate__animated  animate__slideInUp">
        <p className="font-helveticaBold text-[2.2em] md:text-[2.9em] text-center">WHO WE ARE</p>
      </div>

      <div
        className="w-[90%] max-w-[1000px] mx-auto mt-[100px] animate__animated  animate__slideInUp animate__delay-1s flex flex-col items-center"
        // style={{ gridTemplateColumns: "auto auto auto", gridTemplateRows: "auto auto" }}
      >
        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-[20px] mx-auto mt-8 animate__animated animate__slideInUp animate__delay-1s">
          {memberList1.map((member, i) => {
            return (
              <div className="flex flex-col items-center">
                <Member member={member} memberClicked={memberClicked} key={i} />
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-[20px] mx-auto mt-8 animate__animated animate__slideInUp animate__delay-1s">
          {memberList2.map((member, i) => {
            return (
              <div className="flex flex-col items-center">
                <Member member={member} memberClicked={memberClicked} key={i} />
              </div>
            );
          })}
        </div>
      </div>

      <MemberModal member={clickedMember} closeClicked={closeModalClicked} shown={isModalShown} />
    </>
  );
}

export default AboutUsContent;
