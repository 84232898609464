import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { API_URL } from "src/common/function";

export const refreshAccessToken = async () => {
  return axios.post(API_URL + "/api/auth/refresh", null, { withCredentials: true });
  // return axios.post(API_URL + "/api/auth/refresh");
};

export const refreshToken = async () => {
  try {
    let response = await refreshAccessToken();
    if (response.status == 201) {
      const token = response.data.accessToken;
      return token;
    }
  } catch (error) {
    toast(error.response.statusText);
    localStorage.removeItem("financing_accessToken");
    localStorage.removeItem("financing_user");
  }
};

export const setNameAndAddress = async (user, fullName, address, token) => {
  return axios.post(
    API_URL + "/api/user/set_name_address",
    {
      email: user.email,
      full_name: fullName,
      address: address,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateNDASignedState = async (token, email, state) => {
  return axios.post(
    API_URL + "/api/user/update_nda_sent",
    {
      email: email,
      state: state,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getAllInvestors = async (token) => {
  return axios.get(API_URL + "/api/user/get_investors", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
