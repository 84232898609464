import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const IntroductoryVideo = () => {
  const [videoClicked, setVideoClicked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [videoMode, setVideoMode] = useState("object-cover");

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("screenWidth: ", screenWidth);
    if (screenWidth < 1024) {
      setVideoMode("object-fit");
    } else {
      setVideoMode("object-cover");
    }
  }, [screenWidth]);

  const viewVideo = () => {
    if (isChecked) {
      setVideoClicked(true);
    }
  };

  const toggleFullScreen = () => {
    // if (!document.fullscreenElement) {
    //   setVideoMode("object-contain");
    //   document.documentElement.requestFullscreen().then(() => {
    //     setIsFullScreen(true);
    //   });
    // } else {
    //   setVideoMode("object-cover");
    //   document.exitFullscreen().then(() => {
    //     setIsFullScreen(false);
    //   });
    // }
  };

  return videoClicked ? (
    <div className={`video-player ${isFullScreen ? "fullscreen" : ""}`}>
      {/* <video src={process.env.PUBLIC_URL + "/video/introvideo.mp4"} onClick={toggleFullScreen} controls /> */}
      <video
        autoPlay
        unmuted
        loop
        className={`w-screen h-screen ${videoMode}`}
        onClick={toggleFullScreen}
        controls
        disablePictureInPicture
      >
        <source src={process.env.PUBLIC_URL + "/video/investorvideo.mp4"} type="video/mp4" />
      </video>
    </div>
  ) : (
    <div className="bg-white w-screen h-screen">
      <img src="/images/common/bluelogo.png" className="mx-auto w-[250px] object-cover z-20 mt-[100px]" />
      <p className="font-helveticaBold text-[1.2em] sm:text-[1.5em] text-center mt-7">Disclaimer</p>
      <div className="max-w-[1000px] px-5 mx-auto">
        <p className="font-helveticaRoman text-[1.1em] sm:text-[1.3em] mt-5">
          The content presented in this video is for informational purposes only and is not intended to be, nor should
          it be construed as, a formal prospectus or an offer to sell or a solicitation of an offer to buy any
          securities. All viewers and potential investors are strongly advised to consult the company's formal
          prospectus for a complete and accurate picture of the investment opportunity, risks, terms, and conditions.
        </p>
        <p className="font-helveticaRoman text-[1.1em] sm:text-[1.3em] mt-5">
          Reliance upon any information provided in this video for the purpose of making an investment decision is
          solely at the viewer's own risk. The company, its affiliates, agents, directors, officers, employees, and
          representatives expressly disclaim any and all liability or responsibility for any direct, indirect, or
          consequential loss or damage of any kind whatsoever arising directly or indirectly from: (i) reliance on any
          information contained in this video, (ii) any error, omission or inaccuracy in any such information or (iii)
          any action resulting from such information.
        </p>
        <p className="font-helveticaRoman text-[1.1em] sm:text-[1.3em] mt-5">
          By viewing this video, you acknowledge and agree that you will not make any claims against the company, its
          affiliates, agents, directors, officers, employees, or representatives based on the content of this video.
          Your sole and exclusive source of information for making an informed investment decision should be the
          company's formal prospectus and consultation with your personal financial and legal advisors.
        </p>
        <p className="font-helveticaRoman text-[1.1em] sm:text-[1.3em] mt-5">
          No representations or warranties, either express or implied, of any kind are made with respect to the
          information contained herein. All information is subject to change, amendment, or supplementation without
          notice.
        </p>
        <div className="flex items-center mt-3">
          <input
            type="checkbox"
            className="w-[22px] h-[22px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mr-[5px]"
            checked={isChecked}
            onChange={(value) => setIsChecked(value)}
          />
          <p className="font-helveticaRoman text-[0.8em] sm:text-[1.3em]">
            I have read, understood, and agree to the terms of this disclaimer
          </p>

          <p
            onClick={viewVideo}
            className="ml-3 font-helveticaRoman text-[0.8em] sm:text-[1.3em] text-default-color underline cursor-pointer"
          >
            View video
          </p>
        </div>
      </div>
    </div>
  );
};

export default IntroductoryVideo;
