import React from "react";
import { useNavigate } from "react-router-dom";

import "./style.css";

function Member(props) {
  const navigate = useNavigate();

  const loginClicked = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  const memberClicked = (e) => {
    e.preventDefault();
    props.memberClicked(props.member);
  };

  return (
    <div className="w-[300px] h-[450px] bg-[#F7F7F7] relative card-zoom cursor-pointer" onClick={memberClicked}>
      <img src={props.member.photo} className="w-full h-[360px] rounded-[8px] object-cover" />

      {/* <div className="w-[37px] h-[100px] rounded-[5px] absolute right-[10px] bottom-[105px] z-10 bg-[#F7F7F7] flex flex-col p-[5px] gap-[5px]">
        <a href="#">
          <img src="/images/aboutus/facebook.png" />
        </a>
        <a href="#">
          <img src="/images/aboutus/twitter.png" />
        </a>
        <a href="#">
          <img src="/images/aboutus/linkedin.png" />
        </a>
      </div> */}

      <p className="font-helveticaRoman text-[1.7em] text-[#0F0F1E] mt-[10px]">{props.member.name}</p>
      <p className="font-helveticaRoman text-[1em] text-[#737171]">{props.member.job}</p>
    </div>
  );
}

export default Member;
