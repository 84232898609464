import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { getImageUrl } from "src/common/function";
function Footer() {
  const navigate = useNavigate();

  return (
    <div className="h-[310px] bg-footer-bg w-full">
      <div className="h-[230px] w-full items-center justify-center flex">
        <div className="w-full max-w-[1200px] h-[145px] gap-[20px] md:gap-0 items-start md:items-center justify-between flex flex-col md:flex-row px-[20px]">
          <div className="w-full sm:w-[500px] h-full flex items-center justify-between">
            <div className="flex flex-col justify-between w-[55px] h-[80px]">
              <p
                className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full text-left cursor-pointer"
                onClick={() => {
                  navigate("/news");
                }}
              >
                News
              </p>
              <p
                className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full text-left cursor-pointer"
                onClick={() => {
                  navigate("/pricing");
                }}
              >
                Pricing
              </p>
            </div>

            <div className="flex flex-col justify-between w-[80px] h-[80px]">
              <p
                className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full cursor-pointer text-left"
                onClick={() => navigate("/faq")}
              >
                FAQ
              </p>
              <p
                className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full cursor-pointer text-left"
                onClick={() => navigate("/aboutus")}
              >
                About Us
              </p>
            </div>

            <div className="flex flex-col  w-[90px] justify-between h-[80px]">
              <p
                className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full cursor-pointer text-left"
                onClick={() => navigate("/contactus")}
              >
                Contact Us
              </p>
              <a href="/disclaimer" target="_blank">
                <p
                  // onClick={() => navigate("/disclaimer")}
                  className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full text-left cursor-pointer"
                >
                  Disclaimer
                </p>
              </a>
            </div>

            <div className="flex flex-col  justify-between w-[140px] h-[80px] gap-2">
              <a href="https://www.finra.org/" target="_blank">
                <p className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full text-left cursor-pointer">
                  Member of FINRA
                </p>
              </a>
              <a href={getImageUrl("/pdfs/BCP Disclosure.pdf")} target="_blank">
                <p className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full text-left cursor-pointer">
                  BCP Disclosure
                </p>
              </a>
            </div>

            <div className="flex flex-col  justify-between w-[100px] h-[80px] gap-2">
              <p className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full text-left cursor-pointer">
                Form ATS-N
              </p>
              <a href="https://brokercheck.finra.org/firm/summary/324098" target="_blank">
                <p className="font-helveticaLight text-[0.8em] sm:text-[1.0625em] text-white text-opacity-80 w-full cursor-pointer text-left">
                  Brokercheck
                </p>
              </a>
            </div>
          </div>

          <div className="w-[230px] h-full">
            <p className="h-[28px] font-helveticaLight text-[1.0em] md:text-[1.375em] text-white">Follow Us</p>
            <div className="w-full items-center justify-between flex mt-[10px]">
              {/* <img src="/images/footer/google_icon.png" />
              <img src="/images/footer/facebook_icon.png" /> */}
              <a href="https://www.linkedin.com/company/admiral-securities-llc/" target="_blank">
                <img src="/images/footer/linkedin_icon.png" />
              </a>
              {/* <img src="/images/footer/youtube_icon.png" /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="h-[calc(100%-230px)] w-full relative flex items-center justify-center">
        <div className="w-[80%] absolute h-[1px] bg-white opacity-[18%] top-0 left-[10%]"></div>
        <p className="font-helveticaLight text-[0.9375em] text-white">Copyright © 2024 Admiral. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
