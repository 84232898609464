import React, { useEffect, useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import "animate.css";

import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";
import { validateEmail } from "../../common/function";

function Signup() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termChecked, setTermChecked] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    window.location.href = "https://form.jotform.com/233526600552147";
  }, []);

  // const onEmailChanged = (e) => {
  //   setEmail(e.target.value);
  // };

  // const onUsernameChanged = (e) => {
  //   setUserName(e.target.value);
  // };

  // const onPasswordChanged = (e) => {
  //   setPassword(e.target.value);
  // };

  // const onConfirmPasswordChanged = (e) => {
  //   setConfirmPassword(e.target.value);
  // };

  // const signIn = (e) => {
  //   e.preventDefault();
  //   navigate("/login");
  // };

  // const signup = async (e) => {
  //   e.preventDefault();

  //   if (handleValidation()) {
  //   }
  // };

  // const handleValidation = () => {
  //   let errors = {};
  //   let formIsValid = true;

  //   if (userName == "") {
  //     errors["userName"] = "UserName cannot be empty";
  //     formIsValid = false;
  //   }
  //   if (!validateEmail(email)) {
  //     errors["email"] = "Email address is invalid";
  //     formIsValid = false;
  //   }
  //   if (password == "") {
  //     errors["password"] = "Password cannot be empty";
  //     formIsValid = false;
  //   }
  //   if (password != confirmPassword) {
  //     errors["confirmPassword"] = "Passwords doesn't match";
  //     formIsValid = false;
  //   }
  //   if (!termChecked) {
  //     errors["termChecked"] = "Please agree to Terms & Policy";
  //     formIsValid = false;
  //   }

  //   setErrors(errors);
  //   console.log(errors);
  //   return formIsValid;
  // };

  return (
    <></>
    // <div className="mb-[200px]">
    //   <div className="w-[90%] max-w-[450px] ml-auto mr-auto mt-[40px] animate__animated  animate__slideInUp">
    //     <p className="font-helveticaRoman text-default-color text-[2.1875em] text-center">Create an Account</p>
    //     <div className="mt-2 mx-auto w-fit">
    //       <span className="font-helveticaRoman text-[#393939] text-[1.0625em] mr-[10px]">Already have an account?</span>
    //       <a href="#" onClick={signIn} className="decoration-[#e54335]">
    //         <span className="font-helveticaRoman text-[#e54335] text-[1.0625em]">Sign In</span>
    //       </a>
    //     </div>
    //     <div className="mt-4">
    //       <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">Username</span>
    //       <input
    //         value={userName}
    //         type="text"
    //         onChange={onUsernameChanged}
    //         className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
    //       />
    //       <span className="text-red-500">{errors["userName"]}</span>
    //     </div>
    //     <div className="mt-4">
    //       <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">Email Address</span>
    //       <input
    //         value={email}
    //         type="email"
    //         onChange={onEmailChanged}
    //         className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
    //       />
    //       <span className="text-red-500">{errors["email"]}</span>
    //     </div>

    //     <div className="mt-4">
    //       <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">Phone Number</span>
    //       <Input
    //         placeholder="Enter phone number"
    //         value={phone}
    //         onChange={setPhone}
    //         className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
    //       />
    //     </div>

    //     <div className="mt-4">
    //       <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">Password</span>
    //       <input
    //         value={password}
    //         type="password"
    //         onChange={onPasswordChanged}
    //         className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
    //       />
    //       <span className="text-red-500">{errors["password"]}</span>
    //     </div>

    //     <div className="mt-4">
    //       <span className="font-helveticaRoman text-[#737171] text-[0.9375em]">Confirm Password</span>
    //       <input
    //         value={confirmPassword}
    //         type="password"
    //         onChange={onConfirmPasswordChanged}
    //         className="w-full h-[55px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mt-2 pl-3 pr-3"
    //       />
    //       <span className="text-red-500">{errors["confirmPassword"]}</span>
    //     </div>

    //     <div className="flex items-center mt-[25px]">
    //       <input
    //         type="checkbox"
    //         checked={termChecked}
    //         onChange={() => setTermChecked(!termChecked)}
    //         className="w-[22px] h-[22px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mr-[5px]"
    //       />
    //       <label className="font-helveticaRoman text-[#737171] text-[0.9375em] ml-[5px]">I agree to</label>
    //       <a href="#" onClick={signIn} className="decoration-default-color underline ml-[5px]">
    //         <span className="font-helveticaRoman text-default-color text-[.9375em]">Terms and Policy</span>
    //       </a>
    //     </div>
    //     <span className="text-red-500">{errors["termChecked"]}</span>
    //     <a
    //       href="#"
    //       onClick={signup}
    //       className="bg-default-color w-full h-[52px] rounded-md flex items-center justify-center mt-[40px]"
    //     >
    //       <span className="font-helveticaRoman text-[1.125em] text-white">Sign Up</span>
    //     </a>
    //     <p className="font-helveticaRoman text-[#737171] text-[0.875em] mt-[25px]">
    //       <b>Note: </b>Your personal data will be used to support your experience throughout this website, to manage
    //       access to your account, and for other purposes described in our privacy policy
    //     </p>
    //   </div>
    // </div>
  );
}

export default Signup;
