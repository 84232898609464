import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const IntroVideo = () => {
  const [videoClicked, setVideoClicked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [videoMode, setVideoMode] = useState("object-cover");

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("screenWidth: ", screenWidth);
    if (screenWidth < 1024) {
      setVideoMode("object-fit");
    } else {
      setVideoMode("object-cover");
    }
  }, [screenWidth]);

  const viewVideo = () => {
    if (isChecked) {
      setVideoClicked(true);
    }
  };

  const toggleFullScreen = () => {
    // if (!document.fullscreenElement) {
    //   setVideoMode("object-contain");
    //   document.documentElement.requestFullscreen().then(() => {
    //     setIsFullScreen(true);
    //   });
    // } else {
    //   setVideoMode("object-cover");
    //   document.exitFullscreen().then(() => {
    //     setIsFullScreen(false);
    //   });
    // }
  };

  return videoClicked ? (
    <div className={`video-player ${isFullScreen ? "fullscreen" : ""}`}>
      {/* <video src={process.env.PUBLIC_URL + "/video/introvideo.mp4"} onClick={toggleFullScreen} controls /> */}
      <video
        autoPlay
        unmuted
        loop
        className={`w-screen h-screen ${videoMode}`}
        onClick={toggleFullScreen}
        controls
        disablePictureInPicture
      >
        <source src={process.env.PUBLIC_URL + "/video/introvideo.mp4"} type="video/mp4" />
      </video>
    </div>
  ) : (
    <div className="bg-white w-screen h-screen">
      <img src="/images/common/bluelogo.png" className="mx-auto w-[250px] object-cover z-20 mt-[100px]" />
      <p className="font-helveticaBold text-[1.2em] sm:text-[1.5em] text-center mt-7">Disclaimer</p>
      <div className="max-w-[1000px] px-5 mx-auto">
        <p className="font-helveticaRoman text-[1.1em] sm:text-[1.3em] mt-5">
          The information presented in this video has been prepared with the intent to provide general knowledge about
          our products and/or services. While we endeavor to ensure that the information contained herein is accurate
          and up-to-date, we make no representations or warranties of any kind, express or implied, about the
          completeness, accuracy, reliability, suitability, or availability of the content in this video.
        </p>
        <p className="font-helveticaRoman text-[1.1em] sm:text-[1.3em] mt-5">
          Viewers are encouraged to verify any information presented in this video directly with our company before
          making any decisions or taking any actions based on such information. Reliance on any information provided in
          this video is strictly at your own risk.
        </p>
        <p className="font-helveticaRoman text-[1.1em] sm:text-[1.3em] mt-5">
          By viewing this video, you hereby acknowledge and agree that our company, its affiliates, agents, directors,
          officers, employees, and representatives shall not be held liable for any direct, indirect, consequential, or
          incidental damages or losses arising out of, or in connection with, the use of or reliance on any information
          presented in this video.
        </p>

        <div className="flex items-center mt-3">
          <input
            type="checkbox"
            className="w-[22px] h-[22px] border-solid border-[1px] border-[#D9D9D9] outline-[#D9D9D9] rounded-lg mr-[5px]"
            checked={isChecked}
            onChange={(value) => setIsChecked(value)}
          />
          <p className="font-helveticaRoman text-[0.8em] sm:text-[1.3em]">
            I have read, understood, and agree to the terms of this disclaimer
          </p>

          <p
            onClick={viewVideo}
            className="ml-3 font-helveticaRoman text-[0.8em] sm:text-[1.3em] text-default-color underline cursor-pointer"
          >
            View video
          </p>
        </div>
      </div>
    </div>
  );
};

export default IntroVideo;
